<template>
    <div
      v-if="$device.laptop"
      data-professionalpagename="laflamme"
      data-bookingwidgeturlparams="companyId=120424"
      :data-language="$prismic.lang"
      :data-label="$t('common.bookAppointment')"
      data-url="https://www.gorendezvous.com/"
      class="gorendezvous-button grv-btn-wrap"
      data-buttoncolor="custom"
      data-width="232px"
      data-height="56px"
      style="margin-top: 8px;">
      <a href="https://www.gorendezvous.com/laflamme?companyId=120424" class="button -img" target="GOrendezvous">
        <img src="/images/GOrendezvous.png" alt=""/>
        {{ $t('common.bookAppointment') }}
      </a>
    </div>

  <a v-else href="https://www.gorendezvous.com/homepage/120424" target="_blank" rel="noopener" class="button -img">
    <img src="/images/GOrendezvous.png" alt=""/>
    {{ $t('common.bookAppointment') }}
  </a>
</template>

<script>
export default {
  name: 'GoRendezVousButton',
  mounted() {
    if (!this.$device.laptop) return

    const sid = 'gorendezvous-bookingwidget-script'
    const sEl = document.getElementById(sid)
    const ds = (Math.floor(new Date().getTime()/(1000*60*30))*(1000*60*30)).toString()
    const script = document.createElement('script')
    if (sEl) sEl.parentNode.removeChild(sEl)
    script.id = sid
    script.src = `https://www.gorendezvous.com/Scripts/gorendezvous.bookingWidgetV2.min.js?v=${ds}`
    document.body.appendChild(script)
  }
}
</script>
