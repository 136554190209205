<template>
  <div v-if="$prismic.isReady" class="layout">
    <Transition name="layout__view" mode="out-in">
      <RouterView :key="$route.name" class="layout__view" />
    </Transition>

    <NavigationFooter />
    <NavigationTop @toggle-sidenav="isSideNavOpen = !isSideNavOpen" />
    <NavigationSide :is-open="isSideNavOpen" @close="isSideNavOpen = false" />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isSideNavOpen: false
    }
  }
}
</script>
