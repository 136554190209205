export default (() => {
  try {
    window.localStorage.setItem('test', 0)
    window.localStorage.getItem('test')
    window.localStorage.removeItem('test')
    return window.localStorage
  } catch (e) {
    return {
      setItem() {},
      getItem() {},
      removeItem() {}
    }
  }
})()
