<template>
  <article :class="className">
    <div class="layout-dropdown__title" @click="onClickToggle">
      <h3>{{ title }}</h3>

      <figure class="layout-dropdown__icon">
        <svg-icon id="arrow-medium" />
      </figure>
    </div>

    <DropDown :is-open="isOpen" content-class="layout-dropdown__content">
      <slot />
    </DropDown>
  </article>
</template>

<script>
export default {
  name: 'HomeDropdown',
  props: {
    title: { type: String, default: null },
    isOpen: { type: Boolean, default: false }
  },
  computed: {
    className() {
      return ['layout-dropdown__item', { '-open': this.isOpen }]
    }
  },
  methods: {
    onClickToggle() {
      if (this.isOpen) return this.$emit('close')
      this.$emit('open')
    }
  }
}
</script>
