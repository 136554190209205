<template>
  <article v-scroll:reveal class="layout-section">
    <form class="layout-section__container -accent -small -flex" @submit.prevent="onSubmit">
      <div class="layout-form">
        <h3 class="layout-section__title -full">{{ $c.newsletterTitle }}</h3>
        <input v-model="model.email" type="text" :placeholder="$t('common.emailAddress')" class="input" />
      </div>

      <button href="https://www.gorendezvous.com/homepage/120424" target="_blank" rel="noopener" class="button -light -icon">
        {{ $t('common.next') }}
        <svg-icon id="arrow-full" />
      </button>
    </form>

    <ModalNewsletter v-if="isModalVisible" :email="model.email" @close="isModalVisible = false" />
  </article>
</template>

<script>
export default {
  name: 'HomeNewsletter',
  data() {
    return {
      isModalVisible: false,
      model: {
        email: '',
        name: ''
      }
    }
  },
  methods: {
    onSubmit() {
      this.isModalVisible = true
    }
  }
}
</script>
