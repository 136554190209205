<template>
  <footer class="navigation-footer">
    <section class="navigation-footer__split -top">
      <div class="navigation-footer__section">
        <h5 class="navigation-footer__title">Magog / Orford</h5>
        <a class="navigation-footer__link" href="https://goo.gl/maps/mXFYbPWJrmmPY8Tp6" target="_blank" rel="noopener">2381 Rue Principale O, suite 210<br/>Magog, Québec, J1X 0J4</a>
      </div>

      <div class="navigation-footer__section">
        <h5 class="navigation-footer__title">Granby</h5>
        <a class="navigation-footer__link" href="https://goo.gl/maps/zjcbGeGQCGTKpjqW8" target="_blank" rel="noopener">41 Rue Court<br/>Granby, Québec, Canada J2G 4Y7</a>
      </div>
    </section>

    <section class="navigation-footer__split -alt">
      <div class="navigation-footer__section">
        <a class="navigation-footer__link" href="https://www.gorendezvous.com/laflamme" targt="_blank" rel="noopener">{{ $t('common.bookAppointment') }}</a>
        <p class="navigation-footer__station">819-201-5836</p>
      </div>

      <div class="navigation-footer__section">
        <a class="navigation-footer__link" href="mailto:sean@acupuncturelaflamme.com">sean@acupuncturelaflamme.com</a>
        <a class="navigation-footer__link" href="https://www.facebook.com/acupuncturelaflamme/" target="_blank" rel="noopener">Facebook</a>
      </div>
    </section>

    <div class="navigation-footer__bottom">
      <p>{{ currentYear }} &copy; Acupuncture Sean Laflamme</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'NavigationFooter',
  data() {
    return {
      currentYear: new Date().getFullYear(),
      links: [
        { name: 'home', label: 'Home' },
        { name: 'about', label: 'About' }
      ]
    }
  }
}
</script>
