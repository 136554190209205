<template>
  <article v-scroll:reveal="{delay:100}"  class="layout-grid -alone">
    <figure class="layout-grid__box" style="background-image: url('/images/gallery/gallery-4.jpg');"/>
    <figure class="layout-grid__box" style="background-image: url('/images/gallery/gallery-1.jpg');"/>
    <figure class="layout-grid__box" style="background-image: url('/images/gallery/gallery-2.jpg');"/>
    <figure class="layout-grid__box" style="background-image: url('/images/gallery/gallery-6.jpg');"/>
  </article>
</template>

<script>
export default {
  name: 'HomeGallery'
}
</script>
