import pace from 'pace-progress'

pace.start()

pace.once('hide', () => {
  window.prerenderReady = true
})

fetch('/icons.svg')
  .then(response => response.text())
  .then(sprite => document.body.insertAdjacentHTML('afterbegin', sprite))
