<template>
  <article v-scroll:reveal class="layout-section">
    <section class="layout-section__container -center -accent">
      <!-- <p class="layout-section__heading -spacing">Le contexte exceptionnel lié à la pandémie au <a href="https://www.quebec.ca/sante/problemes-de-sante/a-z/coronavirus-2019/" target="_blank" rel="noopener" class="layout-section__underline">Covid-19</a> force les professionnels à s’adapter pour répondre aux besoins des patients. Nous pouvons communiquer des recommandations et établir des modalités de traitement que vous pouvez réaliser vous-même sous supervision.</p> -->
      <p class="layout-section__heading -spacing">{{ $c.alertContent }}</p>

      <ul class="layout-section__list">
        <h4 v-for="line in contentList" :key="line">{{ line }}</h4>
      </ul>
    </section>
  </article>
</template>

<script>
export default {
  name: 'HomeCta',
  computed: {
    contentList() {
      return this.$c.alertContent2.split('\n')
    }
  }
}
</script>
