import Vue from 'vue'
import VueRouter from 'vue-router'
import { Home } from '@/views'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: '-active',
  scrollBehavior: (to, from, savedPosition) => (to.hash ? { selector: to.hash } : savedPosition || { x: 0, y: 0 }),
  routes: [
    {
      path: '/:lang?',
      name: 'home',
      props: true,
      component: Home
    }
  ]
})

export default router
