<template>
  <section>
    <HomeHeader />
    <HomeContent />
    <HomeService />
    <HomeCta />
    <HomeAbout />
    <HomeQigong />
    <HomeGallery />
    <!-- <HomeAcupuncture /> -->
    <HomeNewsletter />
  </section>
</template>

<script>
import * as partials from './partials'

export default {
  name: 'Home',
  components: { ...partials },
  props: {
    lang: { type: String, default: null }
  },
  watch: {
    lang: {
      immediate: true,
      handler(lang) {
        if (!lang) return this.$router.push({ name: 'home', params: { lang: this.$prismic.lang } })
        this.$prismic.lang = lang
      }
    }
  }
}
</script>
