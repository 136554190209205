<template>
  <Transition name="peekaboo">
    <menu v-if="isVisible" class="navigation-top">
      <section class="navigation-top__container">
        <figure  class="navigation-top__logo" @click="$scroll.toTarget('#home', 50, 350)">
          <img class="navigation-top__img" src="/images/logo.png" alt="" />

          <div class="navigation-top__heading">
            <h1>Acupuncture<strong>Sean Laflamme</strong></h1>
          </div>
        </figure>

        <ul class="navigation-top__list">
          <button
            v-for="({ label, hash }, i) in links"
            :key="label"
            :exact="!i"
            :class="['navigation-top__item', { '-active': activeHash === hash }]"
            @click="onClickLink(hash)">
            {{ label }}
          </button>

          <a class="button -small" href="https://www.gorendezvous.com/homepage/120424" target="_blank" rel="noopener">
          {{ $t('common.bookNow') }}
          </a>

          <NavigationTopLangButton />
        </ul>

        <div class="navigation-top__burger" @click="$emit('toggle-sidenav')">
          <svg-icon id="menu" />
        </div>
      </section>
    </menu>
  </Transition>
</template>

<script>
import NavigationTopLangButton from './NavigationTopLangButton.vue'

export default {
  name: 'NavigationTop',
  components: { NavigationTopLangButton },
  data() {
    return {
      isVisible: false,
      refNode: null,
      hashList: [],
      activeHash: null
    }
  },
  computed: {
    links() {
      return [
        { name: 'home', label: 'Acupuncture', hash:'#acupuncture' },
        { name: 'home', label: this.$t('nav.treatment'), hash:'#service' },
        { name: 'home', label: 'Sean Laflamme', hash:'#about' },
        { name: 'home', label: 'Qigong', hash:'#qigong' }
      ]
    }
  },
  watch: {
    '$device.tabletH': {
      immediate: true,
      handler(isTablet) {
        if (!isTablet) this.isVisible = true
        else this.updateScrollEvents()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.hashList = this.links.map(({ hash }) => hash).reverse()
      this.$scroll.on(this.updateScrollEvents)
    })
  },
  methods: {
    getRefNode() {
      this.refNode = document.querySelector('[data-peekaboo]')
      return this.refNode
    },
    updateScrollEvents() {
      if (!this.$device.tabletH) return

      this.activeHash = this.getActiveHash()
      const refNode = this.refNode || this.getRefNode()
      if (!refNode) return

      this.isVisible = refNode.getBoundingClientRect().top < 72
    },
    onClickLink(hash, offset=40) {
      this.$scroll.toTarget(hash, offset, 350)
    },
    getActiveHash() {
      return this.hashList.find(hash => {
        const hashNode = document.querySelector(hash)
        return hashNode && hashNode.getBoundingClientRect().top < 72
      })
    }
  }
}
</script>

<style lang="scss">
.peekaboo {
  &-enter-active,
  &-leave-active {
    transition: transform 240ms cubic-bezier(0.6, 0.05, 0.01, 0.99);
  }

  &-enter,
  &-leave-to {
    transform: translate3d(0, -100%, 0);
  }

  &-leave,
  &-enter-to {
    transform: translateZ(0);
  }
}
</style>
