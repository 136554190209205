import { parseKey } from './helpers'

const createDropdownContentReducer = content => (map, key) => {
  const { contentKey } = parseKey(key)
  return { ...map, [contentKey]: content[key] }
}

export const reduceDropdownData = dropdowns =>
  dropdowns.reduce(
    ({ en, fr }, dropdown) => {
      if (Object.values(dropdown).some(field => !field)) return { en, fr }

      const enData = Object.keys(dropdown)
        .filter(key => /^en_/.test(key))
        .reduce(createDropdownContentReducer(dropdown), {})

      const frData = Object.keys(dropdown)
        .filter(key => /^fr_/.test(key))
        .reduce(createDropdownContentReducer(dropdown), {})

      return {
        en: [...en, enData],
        fr: [...fr, frData]
      }
    },
    { en: [], fr: [] }
  )
