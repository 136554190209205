<template>
  <article id="qigong" class="layout-section">
    <h2 v-scroll:reveal class="layout-section__title">{{ $t('qigong.title') }}</h2>
    <h4 v-scroll:reveal="{delay:75}" class="layout-section__subtitle">{{ $t('qigong.subtitle') }}</h4>
    <p v-scroll:reveal="{delay:150}">{{ $t('qigong.content') }}</p>

    <a v-scroll:reveal="{delay:125}" href="http://www.qigonglaflamme.com/" target="_blank" class="button -icon layout-section__cta">
      {{ $t('common.visitQigong') }}
      <svg-icon id="arrow-full" />
    </a>
  </article>
</template>

<script>
export default {
  name: 'HomeAbout',
}
</script>
