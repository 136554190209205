<template>
  <menu class="navigation-header">
    <router-link to="/" tag="figure" class="navigation-header__logo">
      <img class="navigation-header__img" src="/images/logo.png" alt="" />

      <div class="navigation-header__heading">
        <h1>Acupuncture<strong>Sean Laflamme</strong></h1>
      </div>
    </router-link>

    <ul class="navigation-header__options">
      <li class="navigation-header__item" @click="$scroll.toTarget('#acupuncture', 50, 350)">Acupuncture</li>
      <li class="navigation-header__item" @click="$scroll.toTarget('#service', 50, 350)">{{ $t('nav.treatment') }}</li>
      <li class="navigation-header__item" @click="$scroll.toTarget('#about', 50, 350)">Sean Laflamme</li>
      <a class="navigation-header__item"  @click="$scroll.toTarget('#qigong', 50, 350)">Qigong</a>

      <a href="https://www.gorendezvous.com/homepage/120424" target="_blank" rel="noopener" class="navigation-header__item -accent">
        {{ $t('common.bookNow') }}
      </a>
      <NavigationTopLangButton />
    </ul>
  </menu>
</template>

<script>
import NavigationTopLangButton from './NavigationTopLangButton.vue'

export default {
  name: 'NavigationHeader',
  components: { NavigationTopLangButton }
}
</script>
