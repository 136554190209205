<template>
  <li class="navigation-top__lang">
    <RouterLink :to="{ name: 'home', params: { lang } }">{{ lang.toUpperCase() }}</RouterLink>
  </li>
</template>

<script>
export default {
  name: 'NavigationTopLangButton',
  computed: {
    lang() {
      return this.$prismic.lang === 'fr' ? 'en' : 'fr'
    }
  }
}
</script>
