import axios from 'axios'

const { encodeURI } = window

export const createCachedRef = endpoint => {
  let cachedRef = null

  return () =>
    cachedRef
      ? Promise.resolve(cachedRef)
      : axios(endpoint).then(({ data = {} }) => {
          const { refs = [] } = data
          const { ref } = refs.find(({ id }) => id === 'master') || {}
          cachedRef = ref
          return ref
        })
}

export const getQueryString = query =>
  encodeURI(
    Object.entries(query)
      .map(([key, val]) => `${key}=${val}`)
      .join('&')
  )

export const parseKey = key => {
  const [langKey] = key.match(/fr|en(?=_)/)
  const contentKey = key.replace(/^(fr|en)_/, '').replace(/(?:-|_)(\w)/g, ($m, $1) => $1.toUpperCase())

  return { langKey, contentKey }
}
