<template>
  <article id="acupuncture" class="layout-section">
    <h2 v-scroll:reveal class="layout-section__title">{{ $c.acupunctureTitle }}</h2>
    <p v-scroll:reveal="{delay:75}">{{ $c.acupunctureDescription }}</p>
    <h3 v-scroll:reveal="{delay:125}" class="layout-section__subtitle">{{ $c.acupunctureSubtitle }}</h3>
    <p v-scroll:reveal="{delay:200}">{{ $c.acupunctureDescription2 }}</p>

    <section v-scroll:reveal="{delay:275}" class="layout-dropdown">
       <HomeDropdown
        v-for="(isOpen, i) in dropdownStates"
        :key="dropdowns[i].acupunctureLabel"
        :is-open="isOpen"
        :title="dropdowns[i].acupunctureLabel"
        @open="onOpen(i)"
        @close="onClose">
        {{ dropdowns[i].acupunctureDropdown }}
      </HomeDropdown>
    </section>
  </article>
</template>

<script>
import HomeDropdown from './HomeDropdown.vue'

export default {
  name: 'HomeContent',
  components: { HomeDropdown },
  data() {
    return {
      dropdownStates: []
    }
  },
  computed: {
    dropdowns() {
      return this.$c.acupunctureDropdowns
    }
  },
  watch: {
    dropdowns: {
      immediate: true,
      handler({ length }) {
        this.dropdownStates = Array.from(Array(length), () => false)
      }
    }
  },
  methods: {
    onOpen(index) {
      this.dropdownStates = this.dropdownStates.map((x, i) => i === index)
    },
    onClose() {
      this.dropdownStates = this.dropdownStates.map(() => false)
    }
  }
}
</script>
