<template>
  <menu :class="className">
    <div class="navigation-side__content">
      <header class="navigation-side__header">
        <figure class="navigation-side__close" @click="$emit('close')">
          <svg-icon id="close" />
        </figure>
      </header>

      <nav class="navigation-side__list">
        <button
          v-for="({ label, hash }, i) in links"
          :key="label"
          :exact="!i"
          :style="{ transitionDelay: `${200 + (i * 50)}ms` }"
          class="navigation-side__item navigation-side__cascade-item"
          @click="onClickLink(hash)">
          {{ label }}
        </button>

        <a class="button -light navigation-side__cascade-item" :style="{ transitionDelay: `${200 + (links.length * 50)}ms` }" href="https://www.gorendezvous.com/homepage/120424" target="_blank" rel="noopener">
          {{ $t('common.bookAppointment') }}
        </a>
      </nav>
    </div>
  </menu>
</template>

<script>
export default {
  name: 'NavigationSide',
  props: {
    isOpen: { type: Boolean, default: false }
  },
  data() {
    return {
      links: [
        { name: 'home', label: 'Accueil', hash:'#home' },
        { name: 'home', label: 'Acupuncture', hash:'#acupuncture' },
        { name: 'home', label: this.$t('nav.treatment'), hash:'#service' },
        { name: 'home', label: 'Sean Laflamme', hash:'#about' },
        { name: 'home', label: 'Qigong', hash:'#qigong' },
      ]
    }
  },
  computed: {
    className() {
      return ['navigation-side', { '-open': this.isOpen }]
    }
  },
  methods: {
    onClickLink(hash, offset=70) {
      this.$emit('close')
      this.$scroll.toTarget(hash, offset, 350)
    }
  }
}
</script>
