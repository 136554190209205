<template>
  <article id="home" class="home-header">
    <NavigationHeader v-scroll:reveal="{delay:100}" />

    <header v-scroll:reveal="{delay:175}" class="home-header__content">
      <h1 class="home-header__banner">{{ $c.headerTitle }}</h1>

      <div class="home-header__cta">
        <button class="button -ghost" @click="$scroll.toTarget('#acupuncture', 50, 350)">{{ $t('common.findOutMore') }}</button>
        <GoRendezVousButton />
      </div>
    </header>

    <span data-peekaboo />

    <div v-scroll:reveal="{delay:500}"  class="home-header__more" @click="$scroll.toTarget('#acupuncture', 50, 350)">
      <svg-icon id="arrow-full" />
    </div>
  </article>
</template>

<script>
export default {
  name: 'HomeHeader'
}
</script>
