<template>
  <article id="service" class="layout-section">
    <h2 v-scroll:reveal class="layout-section__title">{{ $c.servicesTitle }}</h2>

    <article v-scroll:reveal="{delay:75}" class="layout-grid">
      <section class="layout-grid__box -text" style="background-image: url('/images/services/service-1.jpg');">
        <h3 class="layout-grid__title">{{ $c.servicesHeading1 }}</h3>
        <p>{{ $c.servicesDescription1 }}</p>
      </section>

      <section class="layout-grid__box -text" style="background-image: url('/images/services/service-3.jpg');">
        <h3 class="layout-grid__title">{{ $c.servicesHeading2 }}</h3>
        <p>{{ $c.servicesDescription2 }}</p>
      </section>

      <section class="layout-grid__box -text" style="background-image: url('/images/services/service-2.jpg');">
        <h3 class="layout-grid__title">{{ $c.servicesHeading3 }}</h3>
        <p>{{ $c.servicesDescription3 }}</p>
      </section>

      <section class="layout-grid__box -text" style="background-image: url('/images/services/service-4.jpg');">
        <h3 class="layout-grid__title">{{ $c.servicesHeading4 }}</h3>
        <p>{{ $c.servicesDescription4 }}</p>
      </section>
    </article>
  </article>
</template>

<script>
export default {
  name: 'HomeService'
}
</script>
