<template>
  <section class="modal modal__overlay" @click="$emit('close')">
    <form class="modal__content" @submit.prevent="onSubmit" @click.stop>
      <h4 v-if="submitFailed" class="modal__title">{{ strings.errorMsg }}</h4>
      <h4 v-else-if="wasSubmitted" class="modal__title">{{ strings.successMsg }}</h4>

      <template v-else>
        <h4 class="modal__title">{{ strings.title }}</h4>

        <section class="modal__form" :class="{ '-loading': $mailchimp.isLoading }">
          <input
            ref="firstInput"
            v-model="model.firstName"
            type="text"
            :class="getFieldClass('firstName', '-dark')"
            :placeholder="$t('common.firstName')"
            autofocus
            @focus="onFocus"
          />

          <input
            v-model="model.lastName"
            type="text"
            :class="getFieldClass('lastName', '-dark')"
            :placeholder="$t('common.lastName')"
            @focus="onFocus"
          />

          <input
            v-model="model.email"
            type="text"
            :class="getFieldClass('email', '-dark')"
            :placeholder="$t('common.emailAddress')"
            @focus="onFocus"
          />
        </section>
      </template>

      <footer class="modal__footer">
        <button v-if="!wasSubmitted && !submitFailed" type="submit" class="button -icon">
          {{ strings.submit }}
          <svg-icon id="arrow-full" />
        </button>

        <button
          type="button"
          :class="{ modal__quit: !wasSubmitted && !submitFailed, 'button -icon': wasSubmitted || submitFailed }"
          @click.stop="$emit('close')">
          {{ strings.action }}
        </button>
      </footer>
    </form>
  </section>
</template>

<script>
export default {
  name: 'ModalNewsletter',
  props: {
    email: { type: String, default: null }
  },
  data() {
    return {
      showValidation: false,
      wasSubmitted: false,
      submitFailed: false,
      model: {
        email: this.email,
        firstName: '',
        lastName: ''
      }
    }
  },
  computed: {
    fieldErrors() {
      return {
        email: !/^[^\s\n]+@[^\s\n]+\.[a-z0-9]{2,}$/.test(this.model.email),
        firstName: !this.model.firstName.length,
        lastName: !this.model.lastName.length
      }
    },
    isValid() {
      return Object.values(this.fieldErrors).every(val => !val)
    },
    actionButtonLabel() {
      if (this.wasSubmitted) return { en: 'Back to website', fr: 'Retourner ay site' }
      return this.submitFailed ? { en: 'OK', fr: 'OK' } : { en: 'Cancel', fr: 'Annuler' }
    },
    strings() {
      return this.$prismic.lang === 'fr'
        ? {
          title: 'Nous avons besoin de quelques informations avant de vous inscrire!',
          errorMsg: `Un problème s'est produit lors de votre inscription. Veuillez essayer plus tard.`,
          successMsg: `Merci pour votre inscription!`,
          submit: `M'inscrire à l'infolettre`,
          action: this.actionButtonLabel.fr
        }
        : {
          title: 'We need a bit more information before subscribing!',
          errorMsg: `A problem occured while processing your subscription. Please try again later.`,
          successMsg: `Thank you for subscribing!`,
          submit: 'Subscribe to the newsletter',
          action: this.actionButtonLabel.en
        }
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.firstInput.focus()
    })
  },
  methods: {
    onSubmit() {
      if (!this.isValid) this.showValidation = true
      else if (!this.wasSubmitted && !this.$mailchimp.isLoading) this.sendForm()
    },
    onFocus() {
      this.showValidation = false
    },
    sendForm() {
      if (this.$mailchimp.isLoading) return

      this.$mailchimp.subscribe(this.model)
        .then(() => {
          this.wasSubmitted = true
        })
        .catch(err => {
          this.submitFailed = true
          console.log(`[MailChimp error]: ${err}`)
        })
    },
    getFieldClass(field, ...modifiers) {
      return ['input', ...modifiers, { '-invalid': this.showValidation && this.fieldErrors[field] }]
    }
  }
}
</script>
